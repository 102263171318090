.Edit-Comment-Outer-Form-Container {
    background-color: white;
    display: flex;
    /* width: 700px; */
    width: auto;
}

.Edit-Comment-Inner-Form-Container {
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* border: 2px solid green; */
    /* width: 100%; */
    /* margin: 20px; */
}

.edit-comment-form {
    /* background-color: rgb(236, 236, 236); */
    /* border: 2px solid plum; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 325px;
    border: 1px solid rgb(203, 200, 200);
    /* border-radius: 40px; */
    padding: 12px;
}

.edit-comment-form-user-name-container{
        font-weight: bold;
        text-align: center;
}
.edit-comment-form-container {
    /* background-color: rgb(236, 236, 236); */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.edit-comment-button {
    color: white;
    font-weight: bold;
    background-color: rgb(41, 121, 213);
    border: none;
    border-radius: 4px;
    width: auto;
    height: 40px;
    margin: 10px;
    padding: 10px;
}
.edit-comment-button:disabled{
    cursor: not-allowed;
}

.form-inputs.edit-comment {
    width: 300px;
    margin: 10px;
    height: 250px;
    border:none;
    /* display:flex;
    justify-content: center;
    word-wrap: break-word; */
}
/* .form-inputs.edit-comment:focus {
   outline:none;
} */

.edit-comment-user-pic {
    border-radius: 50%;
    width: 35px;
    height: 35 px;
    display: flex;
    align-self: center;
    margin: 5px;
}

.edit-title-words{
    border-bottom: 1px solid grey;
    text-align: center;
}

.form-inputs.edit-comment{
    resize: none;
}
