.Outer-Form-Container {
display: flex;
flex-direction: column;
justify-content: center;
align-content: center;
align-items: center;
/* border: 2px solid red; */
background-color: white;
border-radius: 7px;
width: 300px;
margin: 2%;
padding: 20px;
box-shadow: 0.5px 0.5px 3px inherit;
}

.create-post-form{
    background-color: white;
}
.errors{
    background-color: white;
}

.Inner-Form-Container{
    /* border: 2px solid yellow; */
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    background-color: white;
}

.create-post-form-title-box{
    display: flex;
    flex-direction: row;
    justify-content:center;
    border-bottom: 1px solid grey;

    background-color: white;
}

.create-post-form-user-name-container{
    display: flex;
    flex-direction: row;
    justify-content:flex-start;
    font-size: 1em;
    font-weight: bold;
    margin: 2%;
    background-color: white;
    align-items: center;
    align-content: center;
    align-self: center;
}

.button-container{
      display: flex;
      flex-direction: row;
      justify-content:center;
      background-color: white;

}

button.create-post-button{
    border: none;
    color: white;
    background-color: rgb(32, 121, 217);
    width: 100%;
    border-radius: 5px;
    padding: 2%;
    margin: 5% 0%;
      font-weight: bold;
      font-size: 15px;
      padding: 10px;


}
.create-post-button:disabled{
    cursor: not-allowed;
}
.create-post-title{
    font-size: 1em;
    background-color: white;
    font-weight:bold
}

.post-content-input{
    border:none;
    background-color: white;
    margin-bottom: 3%;
    resize:none;
    width: 300px;
    height: 250px;
}

.create-post-form-container{
    background-color: white;
}

.upload-picture-button{
    background-color: white;
}

textarea:focus{
    outline:none
}




.create-post-user-info-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    background-color: white;
    margin-bottom: 5px;
}

.create-post-user-profile-pic-container img{
    border-radius: 50%;
    width: 45px;
    height: 45px;
    object-fit: fill;
    margin: 10px;
    align-self: center;
    align-content: center;
    background-color: white;
}

.user-profile-pic {
    background-color: white;
}


.file-input{
    border:none;
    color:white;
    display: flex;
    align-self: center;
    padding: 0px;
    margin: 0px;
    width:100%
}
input::file-selector-button{
    background-color: rgb(7, 170, 7);
    font-weight: bold;
    color: white;
    padding: 10px;
    border: none;
    width: 100%;
    margin: 0px;
    /* border: thin solid grey; */
    border-radius: 5px;
    /* display: none; */
}
