/* TODO Add site wide styles */

/* @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap'); */

/* @import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');

*{

    text-decoration: none;
    font-family: 'Ubuntu',
    sans-serif;
}

input{
    background-color: white;

}

/* img{
        box-shadow:0 0 3px 1.5px rgb(214, 211, 211);
} */

input:focus {
    outline: none
}
h3{
    background-color: white;
}

/* .Outer-Form-Container{
    background-color: white;
} */

html{
    background-color: inherit
}

button:disabled{
    cursor:not-allowed;
}

body {
    /* background-image: url("https://png.pngtree.com/thumb_back/fh260/background/20210206/pngtree-blue-green-glow-light-effect-blur-background-image_556767.jpg");

    background-size:cover */
    background-color: rgb(234, 233, 233)
}
