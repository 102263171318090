.Comment-Outer-Form-Container {
    background-color: white;
    display: flex;
    width: 100%;
    height: auto;
}

.Comment-Inner-Form-Container {
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* border: 2px solid green; */
    width: 100%;
    /* margin: 20px; */
}

.create-comment-form {

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    border-radius: 10px;
    padding: 0.5%;
    margin: 0%;

}

/* .create-comment-form-user-name-container{
        background-color: white;
} */
.create-comment-form-container {
    /* background-color: rgb(236, 236, 236); */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.create-comment-button {
    color: white;
    font-weight: bold;
    background-color: rgb(41, 121, 213);
    border: none;
    border-radius: 4px;
    width: 80px;
    height: 40px;
    margin: 10px;
}

.comment-form-inputs.comment-content-input {
    width: 100%;
    height:auto;
    /* margin: 10px; */
    border-radius: 60px;
    background-color: rgb(234, 233, 233)

}
#comment-input{
        /* width: 600px;
        height:auto;
        /* margin: 10px; */
        border-radius: 60px;
        background-color: rgb(234, 233, 233);
        font-size: 16px;
        border: none

}

.create-comment-user-pic {
    border-radius: 50%;
    width: 50px;
    height: auto;
    display: flex;
    align-self: center;
    margin: 10px 24px 0px 10px;
    border:none;
    outline:none;
    object-fit: cover;
}

.create-comment-button:disabled {
    cursor: not-allowed;
    /* pointer-events: all !important; */
}

.create-comment-button:hover{
    cursor:pointer;
}

.comment-form-inputs.comment-content-input{
    resize: none;

}
.comment-form-inputs.comment-content-input:hover{
    cursor:pointer;
}

.charLeft{
    font-size:12px;
    font-weight: bold;
    color:red;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 0px 18px 0px 0px;
}



.create-comment-container{
    display: flex;
    flex-direction: column;
    /* border: 1px solid grey; */
    border-radius: 7px;
    /* padding: 5px; */
    margin: 20px 2px;
}
