footer {

    display: flex;
    justify-content: space-evenly;
    align-content: center;
    color: grey;
    box-shadow: 0.5px 0.5px 3px inherit;
    border-radius: 7px;
    border: 1px solid rgb(226, 238, 242);
    padding: 25px;
    bottom: 0%;
    margin-bottom: -10px;
    margin-top: -10px;
    position: sticky;
    background-color: white;
    height: 30px;
    font-size: 13px;
}


footer a {
    font-style: none;
    color: grey;

}

.author-name {
    font-size: 13px;
    font-weight: bold;
    margin: 10px;
}
