.outer-sign-container {
    display: flex;
    justify-content: center;
    width: 100%;
     background-color: white
}

.inner-sign-container {
    text-align: center;
    justify-content: center;

    padding: 15px 30px;
    border-width: thin;
    border-style: solid;
    border-radius: 0px;
    background-color: white
}

.title-box {
    /* border-color: rgb(8, 0, 255);
    border-style: solid; */
    text-align: center;
     background-color: white
}

.inner-form-sign-container {
    padding-top: 10px;
     background-color: white
}

.errors {
    justify-content: center;
    color: red;
    padding: 10px;
     background-color: white
}


.title-sign-container {
    border-color: rgb(203, 207, 207);
    border-bottom-style: solid;
    text-align: center;
     background-color: white
}

.form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
     background-color: white

}

.form-sign-inputs {
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
    border-radius: 0px;
    border-width: thin;
    border-radius: 4px;
    background-color: white
}

.button-sign-container {
    text-align: center;
    /* border-color: rgb(255, 0, 247);
    border-style: solid; */
    /* align-content: center;
    align-items: center; */
     background-color: white
}

.Sign-Up-button {
    width: 100%;
    background-color: rgb(30, 165, 37);
    color: white;
    border-radius: 4px;
    border-width: thin;
    padding: 10px;
    margin-top: 5%;
    margin-bottom: 5%;
    border: none;
    font-weight: bold
}

.Sign-Up-button:hover{
    cursor:pointer;
}

.disclaimer-content{
    font-size: 10px;
     background-color: white;
     margin: 3% 0%;
}

h2{
     background-color: white
}

.signup-title-extras{
    background-color: white
}


.Sign-Up-button.disabled {
    cursor: not-allowed;
    /* pointer-events: all !important; */
}


.profile-pic-upload-container{
    margin: 30px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 30px 20px;
    border:  1px solid rgb(164, 162, 162);
}


#user-profile-upload-url{
    /* margin: 10px 0px 3px 0px; */
    font-size: 13px;

}

#profile-image-upload-label{
    font-weight: bold;
    font-size: 14px;
    margin: 15px;
}
