.Demo-Button {
      width: 100%;
      background-color: rgb(40, 119, 209);
      border-radius: 4px;
      color: white;
      padding: 15px;
      border: none;
      font-weight:bold;
      margin-bottom: 4%;
      font-size: 15px;
}

.Demo-Button:hover{
      cursor:pointer;
}
