.login-form-container {
    /* border: 2px solid red; */
    border-radius: 7px;
    /* box-shadow: 2px 2px 2px 2px rgba(213, 209, 209, 0.2); */
    box-shadow:0 0 2px 0.75px rgb(137, 138, 139);
    padding: 25px;
    width: 300px;
    background-color: white;
    font-size: 15px;
    margin: 30px 60px;

}

form .login-form {
 background-color: white;

}

.create-account-button-container {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 2px solid pink; */
    border-top: 1px solid lightgrey;
    background-color: white;
}

.create-account-button {
    display:flex;
    flex-direction: row;
    justify-content: center;
    width: 230px;
    margin: 5% 0%;
    background-color: rgb(38, 190, 38);
    color: white;
    border-radius: 4px;
    padding: 15px;
    border: none;
    margin: 6% 0%;
    font-weight:bold;
    font-size: 15px;


}

.create-account-button:hover{
    cursor:pointer;
}

.login-button {
    width: 100%;
    margin: 5% 0%;
    background-color: rgb(40, 119, 209);
    border-radius: 4px;
    color: white;
    padding: 15px;
    border: none;
    margin-bottom: 10%;
    font-weight:bold;
    font-size: 15px;
}
.login-button:hover{
    cursor: pointer;
}

input {
    width: 95%;
    border-radius: 3px;
    border: 1px solid grey;
    margin: 5px 0px;
    padding: 15px;
    background-color: white;
    font-size: 15px;
}

.login-button-container{
    background-color: white;
}

.login-input-divs {
    background-color: white;
}

/* .errors{
    background-color: white;
    color:red;
    text-align: center;
    font-weight:bold;
    font-size: 12px;
} */
.errors-container{
    background-color: white;
      background-color: white;
      color:red;
      text-align: center;
      font-weight:bold;
      font-size: 12px;
}
