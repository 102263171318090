* {
    -webkit-font-smoothing: antialiased;
}

.navbar-main {
    display: flex;
    flex-direction: row;
    position: sticky;
    width: 100vw;
    justify-content: space-between;
    position: sticky;
    z-index: 4;
    /* top: 0px; */
    background-color: white;
    border: 1px solid rgb(226, 238, 242);
    box-shadow: 0.5px 0.5px 3px inherit;
    border-radius: 7px;
}

#nav-profile-pic {
    box-shadow: 0 0 2px 0.75px rgb(137, 138, 139);

      width: 50px;
      height: 50px;
      border-radius: 50%;
      object-fit: fill;
      margin: 10px;
}

.navbar-inner-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin: 0px 20px;
    background-color: inherit;
}

.Home-Container {
    display: flex;
    width: auto;
}

.logo {
    max-height: 100px;
    max-width: 100px;
    /* border-color: rgb(255, 0, 144);
    border-style: solid; */
}

.profile-button {
    display: flex;
    justify-self: flex-end;
}

.logged-out-profile-container {
    display: flex;
    justify-content: flex-end;
    /* border-style: solid;
    border-color: green; */
    width: 100%;
}

.Right-Side-Container {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* margin-right: 300px; */
    /* border-color: rgb(255, 0, 144);
    border-style: solid; */
    /* width: 25%; */
    justify-content: flex-end;
}

.homepage-welcome-banner {
    display: flex;
    justify-content: center;
    color: #06c;
    align-items: center;
    align-content: center;
    background-color: white;
    /* border-radius: 20px; */
    padding: 25px;
    /* margin-top: 30px; */
    /* box-shadow: 0 0 4px 1.5px rgb(129, 161, 226); */
    font-size: 35px;
    font-weight: bolder;
}

.homepage-welcome-banner-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100%;
    margin-left: 40px;
}

/* .home-page-user-profile-pic-container img{
    width: 50px;
    border-radius: 50%;
    box-shadow:0 0 4px 1.5px rgb(95, 125, 184);
    display: flex;
    margin-right: 20px;

} */
