@import url(https://fonts.googleapis.com/css2?family=Ubuntu&display=swap);
/* TODO Add site wide styles */

/* @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap'); */

/* @import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap'); */

*{

    text-decoration: none;
    font-family: 'Ubuntu',
    sans-serif;
}

input{
    background-color: white;

}

/* img{
        box-shadow:0 0 3px 1.5px rgb(214, 211, 211);
} */

input:focus {
    outline: none
}
h3{
    background-color: white;
}

/* .Outer-Form-Container{
    background-color: white;
} */

html{
    background-color: inherit
}

button:disabled{
    cursor:not-allowed;
}

body {
    /* background-image: url("https://png.pngtree.com/thumb_back/fh260/background/20210206/pngtree-blue-green-glow-light-effect-blur-background-image_556767.jpg");

    background-size:cover */
    background-color: rgb(234, 233, 233)
}

.login-form-container {
    /* border: 2px solid red; */
    border-radius: 7px;
    /* box-shadow: 2px 2px 2px 2px rgba(213, 209, 209, 0.2); */
    box-shadow:0 0 2px 0.75px rgb(137, 138, 139);
    padding: 25px;
    width: 300px;
    background-color: white;
    font-size: 15px;
    margin: 30px 60px;

}

form .login-form {
 background-color: white;

}

.create-account-button-container {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 2px solid pink; */
    border-top: 1px solid lightgrey;
    background-color: white;
}

.create-account-button {
    display:flex;
    flex-direction: row;
    justify-content: center;
    width: 230px;
    margin: 5% 0%;
    background-color: rgb(38, 190, 38);
    color: white;
    border-radius: 4px;
    padding: 15px;
    border: none;
    margin: 6% 0%;
    font-weight:bold;
    font-size: 15px;


}

.create-account-button:hover{
    cursor:pointer;
}

.login-button {
    width: 100%;
    margin: 5% 0%;
    background-color: rgb(40, 119, 209);
    border-radius: 4px;
    color: white;
    padding: 15px;
    border: none;
    margin-bottom: 10%;
    font-weight:bold;
    font-size: 15px;
}
.login-button:hover{
    cursor: pointer;
}

input {
    width: 95%;
    border-radius: 3px;
    border: 1px solid grey;
    margin: 5px 0px;
    padding: 15px;
    background-color: white;
    font-size: 15px;
}

.login-button-container{
    background-color: white;
}

.login-input-divs {
    background-color: white;
}

/* .errors{
    background-color: white;
    color:red;
    text-align: center;
    font-weight:bold;
    font-size: 12px;
} */
.errors-container{
    background-color: white;
      background-color: white;
      color:red;
      text-align: center;
      font-weight:bold;
      font-size: 12px;
}

/* frontend/src/context/Modal.css */
#modal {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;



}

#modal-background {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
}

#modal-content {
    position: absolute;
    background-color: white;
    border-radius: 4px;
    width: 350px;
    /* box-shadow:0 0 2px 1px rgb(214, 211, 211); */
}

.title-words{
    font-size: 1em;
    background-color: white;
}

.edit-post-button{
    /* background-color: rgb(28, 88, 192);
    color:white;
    border:none;
    border-radius: 7px;
    padding: 5px;
    width: 100%;
    margin: 5% 0% */
    border: none;
    color: white;
    background-color: rgb(32, 121, 217);
    width: 100%;
    border-radius: 5px;
    padding: 2%;
    margin: 5% 0%;
    font-weight: bold;
    font-size: 15px;
    padding: 10px;
}
.edit-post-button:hover{
    cursor:pointer;
}
.form-text-input-field{
    height:auto;
}


.Edit-Post-Outer-Form-Container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    background-color: white;
    margin: 2%;
    padding: 20px;

}

.fa-pencil {
    border: none;
    background-color: white;
    color: rgb(22, 72, 199)
}

.fa-trash-can {
    border: none;
    background-color: white;
    color: rgb(22, 72, 199)
}


.modal-button{
    background-color: white;
    border:none
}

.modal-button:hover{
    cursor: pointer;
}

.outer-sign-container {
    display: flex;
    justify-content: center;
    width: 100%;
     background-color: white
}

.inner-sign-container {
    text-align: center;
    justify-content: center;

    padding: 15px 30px;
    border-width: thin;
    border-style: solid;
    border-radius: 0px;
    background-color: white
}

.title-box {
    /* border-color: rgb(8, 0, 255);
    border-style: solid; */
    text-align: center;
     background-color: white
}

.inner-form-sign-container {
    padding-top: 10px;
     background-color: white
}

.errors {
    justify-content: center;
    color: red;
    padding: 10px;
     background-color: white
}


.title-sign-container {
    border-color: rgb(203, 207, 207);
    border-bottom-style: solid;
    text-align: center;
     background-color: white
}

.form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
     background-color: white

}

.form-sign-inputs {
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
    border-radius: 0px;
    border-width: thin;
    border-radius: 4px;
    background-color: white
}

.button-sign-container {
    text-align: center;
    /* border-color: rgb(255, 0, 247);
    border-style: solid; */
    /* align-content: center;
    align-items: center; */
     background-color: white
}

.Sign-Up-button {
    width: 100%;
    background-color: rgb(30, 165, 37);
    color: white;
    border-radius: 4px;
    border-width: thin;
    padding: 10px;
    margin-top: 5%;
    margin-bottom: 5%;
    border: none;
    font-weight: bold
}

.Sign-Up-button:hover{
    cursor:pointer;
}

.disclaimer-content{
    font-size: 10px;
     background-color: white;
     margin: 3% 0%;
}

h2{
     background-color: white
}

.signup-title-extras{
    background-color: white
}


.Sign-Up-button.disabled {
    cursor: not-allowed;
    /* pointer-events: all !important; */
}


.profile-pic-upload-container{
    margin: 30px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 30px 20px;
    border:  1px solid rgb(164, 162, 162);
}


#user-profile-upload-url{
    /* margin: 10px 0px 3px 0px; */
    font-size: 13px;

}

#profile-image-upload-label{
    font-weight: bold;
    font-size: 14px;
    margin: 15px;
}

.Demo-Button {
      width: 100%;
      background-color: rgb(40, 119, 209);
      border-radius: 4px;
      color: white;
      padding: 15px;
      border: none;
      font-weight:bold;
      margin-bottom: 4%;
      font-size: 15px;
}

.Demo-Button:hover{
      cursor:pointer;
}

.profile-button-container {
    display: inline-block;
    position: relative;
    justify-content: center;
    /* padding: 5px; */
    /* border-style: solid; */
    /* border-color: rgb(159, 157, 157);
    border-width: thin;
    border-radius: 50%; */
    /* background-color: #06c; */
    cursor: pointer;
    color: inherit;
}

.actual-button {
    border: none;
    background-color: white;
    border-radius: 0px;
}
.actual-button:hover:{
     background-color: rgb(224, 225, 226);
     /* color: white; */
     border: none;
     cursor: pointer;
     padding: 2px;
     width: 100%;
     border-radius: 0px;
}

.fa-solid {
    /* color:rgb(189, 46, 196); */
    padding: 7px;
}

.dropdown-content {
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: #f9f9f9;
    width: 155px;
    box-shadow: 0.5px 0.5px 3px grey;
    padding: 10px;
    z-index: 5;
    top: 75%;
    border-radius: 5px;
    right: 55px;
    font-size: 16px;
    margin: 10px;
}


.username-container:hover {
    /* background-color: rgb(220, 220, 221); */
    color: white;
    border: none;
    cursor: pointer;
    /* padding: 5px; */
    width: 125px;
    border-radius: 0px;
}

.log-out {
    /* padding: 5px; */
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;


}

.log-out:hover {
    background-color: rgb(224, 225, 226);
    /* color: white; */
    border: none;
    cursor: pointer;
    padding: 2px;
    width: 100%;
    border-radius: 0px;
}

.my-spots {
    padding: 5px;
}

.my-reviews {
    padding: 5px;
}

.my-reviews:hover {
    background-color: rgb(120, 194, 255);
    color: white;
    border: none;
    cursor: pointer;
    /* padding: 5px; */
    width: 125px;
    border-radius: 0px;
}


.my-spots:hover {
    background-color: rgb(11, 58, 13);
    color: white;
    border: none;
    cursor: pointer;
    /* padding: 5px; */
    width: 125px;
    border-radius: 0px;
}

#nav-profile-pic:hover {
    background-color: grey;
}

.sign-up-text {
    padding: 5px;
}

.sign-up-text:hover {
    background-color: rgb(109, 124, 109);
    color: white;
    border: none;
    cursor: pointer;
    /* padding: 5px; */
    width: 125px;
    border-radius: 0px;
}

.log-in-text {
    padding: 5px;
}

.log-in-text:hover {
    background-color: rgb(11, 58, 13);
    color: white;
    border: none;
    cursor: pointer;
    /* padding: 5px; */
    width: 125px;
    border-radius: 0px;
}

.profile-page {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: none;
    cursor: pointer;
    /* padding: 5px; */
    width: 100%;
    border-radius: 0px;
    /* margin: 5px; */
}

.profile-page:hover {
     background-color: rgb(224, 225, 226);
     /* color: white; */
     border: none;
     cursor: pointer;
     padding: 2px;
     width: 100%;
     border-radius: 0px;
}

#nav-section-profile-pic {
    width: 33px;
    height: 33px;
    border-radius: 50%;
    object-fit: fill;
    margin: 10px;

}
#nav-profile-pic{
      width: 35px;
      height: 35px;
      border-radius: 50%;
      object-fit: fill;
      margin: 10px;
}
.nav-profile-pic-section {
    background-color: grey;
    border-radius: 50%;
}

.fa-right-from-bracket {

    margin: 10px;
    background-color: grey;
    border-radius: 50%;
}

.dropdown-container-sections {
    display: flex;
}

* {
    -webkit-font-smoothing: antialiased;
}

.navbar-main {
    display: flex;
    flex-direction: row;
    position: -webkit-sticky;
    position: sticky;
    width: 100vw;
    justify-content: space-between;
    position: sticky;
    z-index: 4;
    /* top: 0px; */
    background-color: white;
    border: 1px solid rgb(226, 238, 242);
    box-shadow: 0.5px 0.5px 3px inherit;
    border-radius: 7px;
}

#nav-profile-pic {
    box-shadow: 0 0 2px 0.75px rgb(137, 138, 139);

      width: 50px;
      height: 50px;
      border-radius: 50%;
      object-fit: fill;
      margin: 10px;
}

.navbar-inner-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin: 0px 20px;
    background-color: inherit;
}

.Home-Container {
    display: flex;
    width: auto;
}

.logo {
    max-height: 100px;
    max-width: 100px;
    /* border-color: rgb(255, 0, 144);
    border-style: solid; */
}

.profile-button {
    display: flex;
    justify-self: flex-end;
}

.logged-out-profile-container {
    display: flex;
    justify-content: flex-end;
    /* border-style: solid;
    border-color: green; */
    width: 100%;
}

.Right-Side-Container {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* margin-right: 300px; */
    /* border-color: rgb(255, 0, 144);
    border-style: solid; */
    /* width: 25%; */
    justify-content: flex-end;
}

.homepage-welcome-banner {
    display: flex;
    justify-content: center;
    color: #06c;
    align-items: center;
    align-content: center;
    background-color: white;
    /* border-radius: 20px; */
    padding: 25px;
    /* margin-top: 30px; */
    /* box-shadow: 0 0 4px 1.5px rgb(129, 161, 226); */
    font-size: 35px;
    font-weight: bolder;
}

.homepage-welcome-banner-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100%;
    margin-left: 40px;
}

/* .home-page-user-profile-pic-container img{
    width: 50px;
    border-radius: 50%;
    box-shadow:0 0 4px 1.5px rgb(95, 125, 184);
    display: flex;
    margin-right: 20px;

} */

.form-inputs.form-text-input-field{
resize:none;
width: 300px;
height: 250px;
padding: 5px;
word-wrap: break-word;
border:none;
}

.edit-post-button:disabled{
    cursor:not-allowed;
}

.Edit-Comment-Outer-Form-Container {
    background-color: white;
    display: flex;
    /* width: 700px; */
    width: auto;
}

.Edit-Comment-Inner-Form-Container {
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* border: 2px solid green; */
    /* width: 100%; */
    /* margin: 20px; */
}

.edit-comment-form {
    /* background-color: rgb(236, 236, 236); */
    /* border: 2px solid plum; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 325px;
    border: 1px solid rgb(203, 200, 200);
    /* border-radius: 40px; */
    padding: 12px;
}

.edit-comment-form-user-name-container{
        font-weight: bold;
        text-align: center;
}
.edit-comment-form-container {
    /* background-color: rgb(236, 236, 236); */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.edit-comment-button {
    color: white;
    font-weight: bold;
    background-color: rgb(41, 121, 213);
    border: none;
    border-radius: 4px;
    width: auto;
    height: 40px;
    margin: 10px;
    padding: 10px;
}
.edit-comment-button:disabled{
    cursor: not-allowed;
}

.form-inputs.edit-comment {
    width: 300px;
    margin: 10px;
    height: 250px;
    border:none;
    /* display:flex;
    justify-content: center;
    word-wrap: break-word; */
}
/* .form-inputs.edit-comment:focus {
   outline:none;
} */

.edit-comment-user-pic {
    border-radius: 50%;
    width: 35px;
    height: 35 px;
    display: flex;
    align-self: center;
    margin: 5px;
}

.edit-title-words{
    border-bottom: 1px solid grey;
    text-align: center;
}

.form-inputs.edit-comment{
    resize: none;
}

.comment-card-main-container{

    /* margin: 10px; */
    /* border: 1px solid rgb(197, 197, 197); */
    /* border-radius: 20px; */
    /* padding: 2%; */
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 2%
}
.comment-info-top-container {
    /* border: 2px solid red; */
    padding: 10px;

    /* background-color: white; */

}

.comment-title {
    /* background-color: white; */

    font-size: 15px;
    font-weight: bold;
    display: flex;
    align-self: flex-start;
    margin: 3px 0px;


}

.comment-card-content-container {
    font-size: 15px;
    background-color: rgb(234, 233, 233);
    border:none;
    border: none;
    border-radius: 20px;
    padding: 15px;
    /* max-width:200px;
    min-width: 200px; */
    word-wrap:break-word;
    text-align: flex-start;
    line-height: 20px;


}
.comment-card-content-container:hover {
    white-space: normal;
}

.comment-card-container {
    /* margin: 10px; */

}

.comments-browser-cards-container{

   border-radius: 20px;
}

.Edit-Delete-Post-Button{
    border:none;
    background-color: white;
}

.Edit-Delete-Post-Button:hover{
    cursor: pointer;
}

.comments-browser-container{
    /* border: 1px solid grey; */
    /* margin: 2px; */
    border-radius: 7px;

}

.post-number-of-comments{
    display: flex;
    color:grey;
    font-weight:bold;
    justify-content: flex-end;
    padding: 10px 20px;
}

.comment-card-user-pic {
border-radius: 50%;
width: 40px;
margin-right: 15px;
height:40px;
object-fit: cover;
}

.comment-card-header-info{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
}

.comment-card-user-pic-name{
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 20px;
}

.posts-browser-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;

}

.posts-browser-cards-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    align-items: center;
    padding: 1%;
    border-radius: 7px;



}

.post-card-title-container {
    /* border: 2px solid orange; */
    background-color: white;
    border-radius: 7px;
}

.post-card-content-container {

    padding: 25px 5px;
    background-color: white;
    border-radius: 7px;
    max-width: 100%;
    word-wrap: break-word;
    resize: none;
    font-size: 15px;
    line-height: 1.5;
}

.post-card-image-container {
    background-color: white;
    border-radius: 7px;
    width: 100%
}

.Edit-Delete-Button-container {

    display: flex;
    justify-content: center;
    background-color: white;
    justify-content: flex-end;
}

.Edit-Delete-Button {
    background-color: rgb(18, 85, 220);
    border-radius: 7px;
    width: auto;
    padding: 5px;
    border: none;
    color: white;
    font-weight: bold;
}

.post-pic {
    width: 100%;
    background-color: white;
    border-radius: 7px;
    padding: 5px;
}

.post-user-info-header-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: center;
    background-color: white;
    width: 100%
}

.post-user-profile-pic {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    object-fit: cover;
    margin: 10px 15px 10px 0px;
    display: flex;
    background-color: white;
}

.post-card-user-name {
    display: flex;
    align-self: center;
    /* justify-self: flex-start;
    justify-content: flex-start; */
    font-weight: bold;
    font-size: 17px;
    background-color: white;
    color: black;
    /* margin-right: 200px; */
}

.posts-browser-user-profile-pic-container {
    background-color: white;
}


.post-card-container {

    box-shadow: 0.5px 0.5px 3px inherit;
    border-radius: 7px;
    margin: 15px;
    padding: 30px;
    background-color: white;
    width: 75%;
}


.fa-trash-can {
    border: none;
    background-color: white;
    color: rgb(22, 72, 199)
}

/* .Edit-Delete-Post-Button{
    background-color: white;
    color: rgb(22, 72, 199);
    border:none;
} */


.post-comment-container {
    background-color: white;
    border: 1px solid rgb(223, 218, 218);
    padding: 5px;
    border-radius: 7px;

}

.leave-comment-container {
    background-color: white;
    margin: 0px;
}

.Main-Header-Edit-Delete-Button-container {
    display: flex;
    justify-content: flex-end;
    margin-left: 5px;

}

/*
.unliked-post-button{
    background-color: white;
    color:rgb(4, 70, 142);
    border: 1px solid rgb(4, 70, 142);
    padding: 4px;
    font-size: 15px;
    font-weight: bold;
    width:auto;
    border-radius: 7px;
    margin: 4px 0px 8px 1px;
}
.unliked-post-button:hover{
    background-color: rgb(48, 136, 212);
    cursor: pointer;
} */

.liked-post-button:hover {
    background-color: lightgrey;
    cursor: pointer;
    /* padding: 5px; */
    /* margin: 5px; */
}

.liked-post-button:focus {
    color: rgb(35, 88, 186);
}

#likes-pic {
    color: white;
    background-color: rgb(35, 88, 186);
    border-radius: 50%;
    font-size: 10px;
}

.likes-counter-pic-container {
    font-size: 12px;
    color: rgb(35, 88, 186);
    display: flex;


}

/* .before-like-container{
  color:#1877f2
} */


.liked-pic-button-container {
    color: rgb(48, 106, 214);
    background-color: inherit;
    border: none;
    padding: 4px;
    font-size: 15px;
    font-weight: bold;
    width: auto;
    border-radius: 7px;
    margin: 8px;

}

.liked-post-button {
    background-color: inherit;
    color: grey;
    border: none;
    padding: 4px;
    font-size: 15px;
    font-weight: bold;
    width: auto;
    border-radius: 7px;
    margin: 8px;

}

.post-likes-comments-number-container {

    color: grey;
    padding: 0px;
    display: flex;
    justify-content: flex-end;
    font-size: 14px;

}

.likes-comments-counter-flex-container {
    display: flex;
    /* justify-content: space-between; */
    justify-content: flex-end;

}

.post-number-section {
    margin: 5px;

}

.post-like-container {
    border-top: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;
    box-shadow: 0.5px 0.5px 3px inherit;
    padding: 5px;
    margin: 10px 0px;
}

.link-user-pic-name {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.Comment-Outer-Form-Container {
    background-color: white;
    display: flex;
    width: 100%;
    height: auto;
}

.Comment-Inner-Form-Container {
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* border: 2px solid green; */
    width: 100%;
    /* margin: 20px; */
}

.create-comment-form {

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    border-radius: 10px;
    padding: 0.5%;
    margin: 0%;

}

/* .create-comment-form-user-name-container{
        background-color: white;
} */
.create-comment-form-container {
    /* background-color: rgb(236, 236, 236); */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.create-comment-button {
    color: white;
    font-weight: bold;
    background-color: rgb(41, 121, 213);
    border: none;
    border-radius: 4px;
    width: 80px;
    height: 40px;
    margin: 10px;
}

.comment-form-inputs.comment-content-input {
    width: 100%;
    height:auto;
    /* margin: 10px; */
    border-radius: 60px;
    background-color: rgb(234, 233, 233)

}
#comment-input{
        /* width: 600px;
        height:auto;
        /* margin: 10px; */
        border-radius: 60px;
        background-color: rgb(234, 233, 233);
        font-size: 16px;
        border: none

}

.create-comment-user-pic {
    border-radius: 50%;
    width: 50px;
    height: auto;
    display: flex;
    align-self: center;
    margin: 10px 24px 0px 10px;
    border:none;
    outline:none;
    object-fit: cover;
}

.create-comment-button:disabled {
    cursor: not-allowed;
    /* pointer-events: all !important; */
}

.create-comment-button:hover{
    cursor:pointer;
}

.comment-form-inputs.comment-content-input{
    resize: none;

}
.comment-form-inputs.comment-content-input:hover{
    cursor:pointer;
}

.charLeft{
    font-size:12px;
    font-weight: bold;
    color:red;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 0px 18px 0px 0px;
}



.create-comment-container{
    display: flex;
    flex-direction: column;
    /* border: 1px solid grey; */
    border-radius: 7px;
    /* padding: 5px; */
    margin: 20px 2px;
}

.Outer-Form-Container {
display: flex;
flex-direction: column;
justify-content: center;
align-content: center;
align-items: center;
/* border: 2px solid red; */
background-color: white;
border-radius: 7px;
width: 300px;
margin: 2%;
padding: 20px;
box-shadow: 0.5px 0.5px 3px inherit;
}

.create-post-form{
    background-color: white;
}
.errors{
    background-color: white;
}

.Inner-Form-Container{
    /* border: 2px solid yellow; */
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    background-color: white;
}

.create-post-form-title-box{
    display: flex;
    flex-direction: row;
    justify-content:center;
    border-bottom: 1px solid grey;

    background-color: white;
}

.create-post-form-user-name-container{
    display: flex;
    flex-direction: row;
    justify-content:flex-start;
    font-size: 1em;
    font-weight: bold;
    margin: 2%;
    background-color: white;
    align-items: center;
    align-content: center;
    align-self: center;
}

.button-container{
      display: flex;
      flex-direction: row;
      justify-content:center;
      background-color: white;

}

button.create-post-button{
    border: none;
    color: white;
    background-color: rgb(32, 121, 217);
    width: 100%;
    border-radius: 5px;
    padding: 2%;
    margin: 5% 0%;
      font-weight: bold;
      font-size: 15px;
      padding: 10px;


}
.create-post-button:disabled{
    cursor: not-allowed;
}
.create-post-title{
    font-size: 1em;
    background-color: white;
    font-weight:bold
}

.post-content-input{
    border:none;
    background-color: white;
    margin-bottom: 3%;
    resize:none;
    width: 300px;
    height: 250px;
}

.create-post-form-container{
    background-color: white;
}

.upload-picture-button{
    background-color: white;
}

textarea:focus{
    outline:none
}




.create-post-user-info-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    background-color: white;
    margin-bottom: 5px;
}

.create-post-user-profile-pic-container img{
    border-radius: 50%;
    width: 45px;
    height: 45px;
    object-fit: fill;
    margin: 10px;
    align-self: center;
    align-content: center;
    background-color: white;
}

.user-profile-pic {
    background-color: white;
}


.file-input{
    border:none;
    color:white;
    display: flex;
    align-self: center;
    padding: 0px;
    margin: 0px;
    width:100%
}
input::file-selector-button{
    background-color: rgb(7, 170, 7);
    font-weight: bold;
    color: white;
    padding: 10px;
    border: none;
    width: 100%;
    margin: 0px;
    /* border: thin solid grey; */
    border-radius: 5px;
    /* display: none; */
}

.main-page-logo-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

}

.landing-page-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: flex-start;
    align-items:flex-start;
    /* border:2px solid red; */
    min-height:100vh;
    min-width:100vw;
    background-color: rgb(242, 238, 238);
    padding-top:8%;

}
.main-page-logo{
    width:30%;
    margin: 0px 20px 20px 20px;
}

.main-page-words{
     display: flex;
     color:rgb(0, 0, 0);
     align-self: center;
     font-size: 30px;
     font-weight: 600;
     margin: 15px;
}

.homepage-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.homepage-left-container {
  background-color: inherit;
  width: 13%;
  margin: 1.5%;
  padding: 10px;
  /* box-shadow: 0 0 2px 0.75px rgb(129 161 226); */
  border-radius: 6px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.homepage-right-container {
  background-color: white;
  width: 13%;
  margin: 2.25%;
  padding: 10px;
  /* box-shadow: 0 0 2px 0.75px rgb(129 161 226); */
  border-radius: 6px;
  display: flex;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.homepage-center-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin-bottom: 15%;
  width: 60%;
}

.new-post-form-component-container {
  display: flex;
  justify-content: center;
  align-self: center;
  padding: 4%;
  /* margin: 4%; */
  width: 100%;
}

.posts-browser-component-container {
  display: flex;
  justify-content: center;
  align-self: center;
}

.create-post-modal-click-input {
  background-color: rgb(234, 233, 233);
  border-radius: 20px;
  display: flex;
  align-self: center;
  padding: 15px 50px;
  color: rgb(94, 91, 91);
  width: 100%;
}

.create-post-click-container {
  border-radius: 6px;
  padding: 12px 7px;
  margin: 0px 90px;
  display: flex;
  flex-direction: row;
  width: 100%;
  box-shadow: 0.5px 0.5px 3px inherit;
}

.create-post-modal-click-post-user-profile-pic {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  box-shadow: 0 0 2px 0.75px rgb(137, 138, 139);
  margin: 5px 10px;
  display: flex;
  object-fit:fill;
}


.author-bio-info-links-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  align-items: center;
  border: none;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0.5px 0.5px 3px inherit;
  /* margin: 37% 10px; */
  font-size: 20px;
}

.author-bio-info-links {
  width: 50px;
  display: flex;
  justify-content: center;
  margin: 20px;
  font-size: 20px;
}

.fa-linkedin {
  color: rgb(59, 137, 220);
  /* margin: 20px; */
}

.fa-square-github {
  color: black;
  /* margin: 20px; */
}

#appacademylogo {
  background-color: inherit;
  width: 5rem;
  margin: 30px;
}

.logo-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.ads-main-container {
  height: auto;
  width: 100%;
  /* margin: 10px; */
  padding: 10px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-content: center;
  /* border: 1px solid grey */
}

.homepage-welcome-banner {
  background-color: inherit;
}

.ad-card {
  font-size: 15px;
  color: rgb(255, 0, 0);
  background-color: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 12%;
  padding: 2%;
  width: 100%;
  height: auto;
  border-radius: 6px;
  align-items: center;
}

.connect-author-title {
  display: flex;
  text-align: center;
  font-weight: 900;
  font-size: 20px;
  margin: 2px;
  padding: 10px;
  border-bottom: 1px solid rgb(111, 108, 108);
  color: rgb(106, 105, 105);
  line-height: 1.5;
}

.ad-content:link {
  text-decoration: none;
  color: rgb(106, 105, 105);
}

a:visited {
  text-decoration: none;
  color: rgb(108, 108, 108);
}

a:hover {
  color: rgb(89, 89, 89);
}

a:active {
  color: rgb(88, 89, 92);
}

.bold {
  font-weight: bolder;
}

.small {
  font-size: 20px;
}

.ad-content {
  margin: 2%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
/*
.left-section-content {

    display: flex;
    justify-content: center;
    flex-direction: column;

}


.connect-author-sections {
    background-color: inherit;
    font-weight: bold;
    display: flex;
    color: rgb(106, 105, 105);
    font-size: 20px;
    margin: 10px;
}


img.ad-image {
    width: 70%;
    display: flex;
    margin: 5px;
} */

.left-section-content {
  display: flex;

  justify-content: center;
  align-content: center;
}

.connect-author-sections {
  background-color: inherit;
  font-weight: bold;
  display: flex;
  color: rgb(106, 105, 105);
  font-size: 20px;
  margin: 10px;
}

img.ad-image {
  width: 70%;
  display: flex;
  margin: 5px;
}

footer {

    display: flex;
    justify-content: space-evenly;
    align-content: center;
    color: grey;
    box-shadow: 0.5px 0.5px 3px inherit;
    border-radius: 7px;
    border: 1px solid rgb(226, 238, 242);
    padding: 25px;
    bottom: 0%;
    margin-bottom: -10px;
    margin-top: -10px;
    position: -webkit-sticky;
    position: sticky;
    background-color: white;
    height: 30px;
    font-size: 13px;
}


footer a {
    font-style: none;
    color: grey;

}

.author-name {
    font-size: 13px;
    font-weight: bold;
    margin: 10px;
}

.user-profile-page-main-container {
    margin-left: 15%;
    margin-right: 15%;
}


.user-profile-page-flex-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%
        /* border: 2px soild grey */
}

.left-user-info-flex-container {
    /* border: 1px solid rgb(218, 214, 214); */
    box-shadow: 0 0 2px 0.75px rgb(234, 233, 233);
    /* margin: 8% 5% 0% 0% ; */
    margin-top: 2%;
    /* margin-bottom: 78px; */
    /* padding: 15px; */
    width: 30%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    /* background-color: white; */
    border-radius: 7px;
}

#profile-page-posts {
    /* width: 50%; */




}

.like-comment-section {
    border-bottom: 1px solid grey;
    padding: 5px 0px;
}

.post-likes-comments-number-container {
    padding: 10px;
    /* border-bottom: 1px solid grey; */
}

.right-user-friends-container {
    padding: 5px;
    margin: 10px;
    background-color: rgb(234, 233, 233);
    color: white;
    border-radius: 7px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* align-items: flex-start; */
    justify-content: flex-start;
    font-weight: bold;
    font-size: 18px;
}



.profile-page-cover-photo-container {
    display: flex;
    justify-content: center;
    width: 100%;
    /* background-color: lightgrey; */
    height: 100%;
    object-fit: fill;
    /* border-radius: 14px; */


}

.profile-page-cover-photo {

    width: 100%;
    /* margin: 10px 0px; */
   object-fit: fill;
   /* display:flex;
   justify-content: center; */
   border-radius: 14px;
   border-bottom-left-radius: 0%;
   border-bottom-right-radius: 0%;
}

.user-profile-header-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    background-color: white;
    padding: 10px;
    z-index: 0;
    /* border-bottom: 1px solid grey; */
    height: 100px;
    padding-bottom: 100px;
    border-radius: 14px;
    border-top-left-radius: 0%;
    border-top-right-radius: 0%;
    width:100%;
}

.profile-header-user-name {
    font-weight: bold;
    font-size: 50px;


}

.user-top-page{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.profile-user-profile-pic {
    border-radius: 50%;
    /* z-index: 1; */
    width: 200px;
    height: 200px;
    margin: 25px;
    border: 5px solid white;
    box-shadow: 0 0 2px 0.75px rgb(137, 138, 139);
}

.friend-profile-pic {
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
}

.user-info-main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    /* margin: 10px; */
    padding: 5px;
    font-size: 20px;
    border-radius: 5px;
    background-color: white;
    color: black
}

.user-intro-title {
    font-size: 40px;
    font-weight: bolder;
    margin: 20px 0px;
}

.profile-user-header-buttons {
    margin: 50px;
    padding: 5px;
}

.add-friend-button,

.remove-friend-button {
    background-color: rgb(39, 97, 199);
    font-weight: bold;
    color: white;
    width: 225px;
    padding: 10px 5px;
    /* margin: 10px; */
    border: none;
    border-radius: 7px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center
}

.cancel-friend-button {
    background-color: grey;
    font-weight: bold;
    color: white;
    width: 225px;
    margin: 5px 10px;
    padding: 15px;
    border-radius: 7px;
    font-size: 20px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center
}

.friendship-dropdown {
    position: relative;
    display: inline-block;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
}

.friendship-menu {
    min-width: auto;
    padding: 12px 16px;
    z-index: 1;
    position: absolute;
    display: inline-block;
}

.menu-item {


    /* list-style: none;
    margin: none;
    border: 1px solid red; */
    display: inline-block;
    /* border: 1px solid rgb(2, 0, 128); */
    position: absolute;

    margin: 3px;
    width: auto;
    /* overflow:auto; */
    /* box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2); */
    /* padding: 12px 16px; */
    margin: 0px;
    z-index: 1;

}

.friends-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: white;
    border-radius: 7px;
    padding: 5px;
    flex-basis: fit-content;
     align-content: center;
     justify-content: center;
}

.Friends-container-title {
    font-weight: bolder;
    font-size: 30px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 15px 0px;
    padding: 10px ;
    color: black;
    background-color: white;
    border-radius: ;
}

.pending-request-container {
    font-weight: bold;
    font-size: 18px;
    display: flex;

}

.friend-request-container {
    border-radius: 14px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    color: black;

}

.friend-request-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    margin: 20px 0px;
    padding: 10px;
    background-color: white;
    width: 180px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;

}

.friend-request-name {
    display: flex;
    margin: 10px;
    font-weight: bolder;
    font-size: 25px
}

.friend-request {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    margin: 7px;
}

.friend-request-button {
    background-color: rgb(28, 127, 240);
    color: white;
    font-size: 13px;
    border-radius: 7%;
    border: 1px solid rgb(47, 120, 244);
    padding: 12px;
    margin: 5px;
    font-weight: bold;
}

.friend-card-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-basis: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.friend-card {
    width: 70px;
    height: auto;
    color: black;
    background-color: white;
    margin: 1px;
    padding: 3px;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 4px;

}

.friends-main-flex-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: white;
    border-radius: 7px;
    padding: 5px;
}

.fa-user-times {
    margin-right: 10px
}


.user-name-intro {
    font-weight: bold;
}

.user-email {
    font-size: 1rem;
}

.user-details {
    margin: 10px
}

.center-user-posts-browser-container {
    width: 60%;

}

.Profile-outer-Form-Container{
width: 600px;
border: 2px solid lightgrey;
padding: 10px;
background-color: white;
}


.Profile-inner-Form-Container{
width: 600px;
padding: 10px;
background-color: white;
}

.create-profile-form{
width: 600px;
padding: 0px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
color: black;
}

.profile-form-inputs {
    margin: 15px 0px;
}

.create-profile-user-info-container{

}


.errors {
color: red;
font-weight: bold;

}


.create-profile-form-container{
margin: 15px;
/* border: 1px solid rgb(223, 218, 218); */
padding: 40px;
background-color: white;
border-radius: 6px;
}

.profile-form-inputs{
margin: 10px 0px;
/* border: 1px solid grey; */
border-radius: 6px;

}

.profile-content-input{

}


#gender{
margin: 10px 20px;
padding: 10px;
font-weight: bold;
font-size: 15px;
border-radius: 7px;


}

#birthdate{
    margin: 10px 20px;
    padding: 10px;
    display: flex;
    justify-content: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 6px;
}
.birthdate-container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
/* .button-container{

} */

.create-profile-button{
padding: 14px 18px;
margin: 10px;
background-color: rgb(28, 121, 215);
color: white;
font-weight: bold;
border-radius: 6px;
border: none;
font-size: 16px;
}

#profile-bio{
    resize: none;
    height: 400px;
    width: 400px;
    color: black;
    padding: 20px;

}

