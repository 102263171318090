.main-page-logo-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

}

.landing-page-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: flex-start;
    align-items:flex-start;
    /* border:2px solid red; */
    min-height:100vh;
    min-width:100vw;
    background-color: rgb(242, 238, 238);
    padding-top:8%;

}
.main-page-logo{
    width:30%;
    margin: 0px 20px 20px 20px;
}

.main-page-words{
     display: flex;
     color:rgb(0, 0, 0);
     align-self: center;
     font-size: 30px;
     font-weight: 600;
     margin: 15px;
}
