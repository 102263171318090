.posts-browser-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;

}

.posts-browser-cards-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    align-items: center;
    padding: 1%;
    border-radius: 7px;



}

.post-card-title-container {
    /* border: 2px solid orange; */
    background-color: white;
    border-radius: 7px;
}

.post-card-content-container {

    padding: 25px 5px;
    background-color: white;
    border-radius: 7px;
    max-width: 100%;
    word-wrap: break-word;
    resize: none;
    font-size: 15px;
    line-height: 1.5;
}

.post-card-image-container {
    background-color: white;
    border-radius: 7px;
    width: 100%
}

.Edit-Delete-Button-container {

    display: flex;
    justify-content: center;
    background-color: white;
    justify-content: flex-end;
}

.Edit-Delete-Button {
    background-color: rgb(18, 85, 220);
    border-radius: 7px;
    width: auto;
    padding: 5px;
    border: none;
    color: white;
    font-weight: bold;
}

.post-pic {
    width: 100%;
    background-color: white;
    border-radius: 7px;
    padding: 5px;
}

.post-user-info-header-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: center;
    background-color: white;
    width: 100%
}

.post-user-profile-pic {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    object-fit: cover;
    margin: 10px 15px 10px 0px;
    display: flex;
    background-color: white;
}

.post-card-user-name {
    display: flex;
    align-self: center;
    /* justify-self: flex-start;
    justify-content: flex-start; */
    font-weight: bold;
    font-size: 17px;
    background-color: white;
    color: black;
    /* margin-right: 200px; */
}

.posts-browser-user-profile-pic-container {
    background-color: white;
}


.post-card-container {

    box-shadow: 0.5px 0.5px 3px inherit;
    border-radius: 7px;
    margin: 15px;
    padding: 30px;
    background-color: white;
    width: 75%;
}


.fa-trash-can {
    border: none;
    background-color: white;
    color: rgb(22, 72, 199)
}

/* .Edit-Delete-Post-Button{
    background-color: white;
    color: rgb(22, 72, 199);
    border:none;
} */


.post-comment-container {
    background-color: white;
    border: 1px solid rgb(223, 218, 218);
    padding: 5px;
    border-radius: 7px;

}

.leave-comment-container {
    background-color: white;
    margin: 0px;
}

.Main-Header-Edit-Delete-Button-container {
    display: flex;
    justify-content: flex-end;
    margin-left: 5px;

}

/*
.unliked-post-button{
    background-color: white;
    color:rgb(4, 70, 142);
    border: 1px solid rgb(4, 70, 142);
    padding: 4px;
    font-size: 15px;
    font-weight: bold;
    width:auto;
    border-radius: 7px;
    margin: 4px 0px 8px 1px;
}
.unliked-post-button:hover{
    background-color: rgb(48, 136, 212);
    cursor: pointer;
} */

.liked-post-button:hover {
    background-color: lightgrey;
    cursor: pointer;
    /* padding: 5px; */
    /* margin: 5px; */
}

.liked-post-button:focus {
    color: rgb(35, 88, 186);
}

#likes-pic {
    color: white;
    background-color: rgb(35, 88, 186);
    border-radius: 50%;
    font-size: 10px;
}

.likes-counter-pic-container {
    font-size: 12px;
    color: rgb(35, 88, 186);
    display: flex;


}

/* .before-like-container{
  color:#1877f2
} */


.liked-pic-button-container {
    color: rgb(48, 106, 214);
    background-color: inherit;
    border: none;
    padding: 4px;
    font-size: 15px;
    font-weight: bold;
    width: auto;
    border-radius: 7px;
    margin: 8px;

}

.liked-post-button {
    background-color: inherit;
    color: grey;
    border: none;
    padding: 4px;
    font-size: 15px;
    font-weight: bold;
    width: auto;
    border-radius: 7px;
    margin: 8px;

}

.post-likes-comments-number-container {

    color: grey;
    padding: 0px;
    display: flex;
    justify-content: flex-end;
    font-size: 14px;

}

.likes-comments-counter-flex-container {
    display: flex;
    /* justify-content: space-between; */
    justify-content: flex-end;

}

.post-number-section {
    margin: 5px;

}

.post-like-container {
    border-top: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;
    box-shadow: 0.5px 0.5px 3px inherit;
    padding: 5px;
    margin: 10px 0px;
}

.link-user-pic-name {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
