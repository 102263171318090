.user-profile-page-main-container {
    margin-left: 15%;
    margin-right: 15%;
}


.user-profile-page-flex-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%
        /* border: 2px soild grey */
}

.left-user-info-flex-container {
    /* border: 1px solid rgb(218, 214, 214); */
    box-shadow: 0 0 2px 0.75px rgb(234, 233, 233);
    /* margin: 8% 5% 0% 0% ; */
    margin-top: 2%;
    /* margin-bottom: 78px; */
    /* padding: 15px; */
    width: 30%;
    height: fit-content;
    /* background-color: white; */
    border-radius: 7px;
}

#profile-page-posts {
    /* width: 50%; */




}

.like-comment-section {
    border-bottom: 1px solid grey;
    padding: 5px 0px;
}

.post-likes-comments-number-container {
    padding: 10px;
    /* border-bottom: 1px solid grey; */
}

.right-user-friends-container {
    padding: 5px;
    margin: 10px;
    background-color: rgb(234, 233, 233);
    color: white;
    border-radius: 7px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* align-items: flex-start; */
    justify-content: flex-start;
    font-weight: bold;
    font-size: 18px;
}



.profile-page-cover-photo-container {
    display: flex;
    justify-content: center;
    width: 100%;
    /* background-color: lightgrey; */
    height: 100%;
    object-fit: fill;
    /* border-radius: 14px; */


}

.profile-page-cover-photo {

    width: 100%;
    /* margin: 10px 0px; */
   object-fit: fill;
   /* display:flex;
   justify-content: center; */
   border-radius: 14px;
   border-bottom-left-radius: 0%;
   border-bottom-right-radius: 0%;
}

.user-profile-header-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    background-color: white;
    padding: 10px;
    z-index: 0;
    /* border-bottom: 1px solid grey; */
    height: 100px;
    padding-bottom: 100px;
    border-radius: 14px;
    border-top-left-radius: 0%;
    border-top-right-radius: 0%;
    width:100%;
}

.profile-header-user-name {
    font-weight: bold;
    font-size: 50px;


}

.user-top-page{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.profile-user-profile-pic {
    border-radius: 50%;
    /* z-index: 1; */
    width: 200px;
    height: 200px;
    margin: 25px;
    border: 5px solid white;
    box-shadow: 0 0 2px 0.75px rgb(137, 138, 139);
}

.friend-profile-pic {
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
}

.user-info-main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    /* margin: 10px; */
    padding: 5px;
    font-size: 20px;
    border-radius: 5px;
    background-color: white;
    color: black
}

.user-intro-title {
    font-size: 40px;
    font-weight: bolder;
    margin: 20px 0px;
}

.profile-user-header-buttons {
    margin: 50px;
    padding: 5px;
}

.add-friend-button,

.remove-friend-button {
    background-color: rgb(39, 97, 199);
    font-weight: bold;
    color: white;
    width: 225px;
    padding: 10px 5px;
    /* margin: 10px; */
    border: none;
    border-radius: 7px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center
}

.cancel-friend-button {
    background-color: grey;
    font-weight: bold;
    color: white;
    width: 225px;
    margin: 5px 10px;
    padding: 15px;
    border-radius: 7px;
    font-size: 20px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center
}

.friendship-dropdown {
    position: relative;
    display: inline-block;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
}

.friendship-menu {
    min-width: auto;
    padding: 12px 16px;
    z-index: 1;
    position: absolute;
    display: inline-block;
}

.menu-item {


    /* list-style: none;
    margin: none;
    border: 1px solid red; */
    display: inline-block;
    /* border: 1px solid rgb(2, 0, 128); */
    position: absolute;

    margin: 3px;
    width: auto;
    /* overflow:auto; */
    /* box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2); */
    /* padding: 12px 16px; */
    margin: 0px;
    z-index: 1;

}

.friends-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: white;
    border-radius: 7px;
    padding: 5px;
    flex-basis: fit-content;
     align-content: center;
     justify-content: center;
}

.Friends-container-title {
    font-weight: bolder;
    font-size: 30px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 15px 0px;
    padding: 10px ;
    color: black;
    background-color: white;
    border-radius: ;
}

.pending-request-container {
    font-weight: bold;
    font-size: 18px;
    display: flex;

}

.friend-request-container {
    border-radius: 14px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    color: black;

}

.friend-request-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    margin: 20px 0px;
    padding: 10px;
    background-color: white;
    width: 180px;
    height: fit-content;

}

.friend-request-name {
    display: flex;
    margin: 10px;
    font-weight: bolder;
    font-size: 25px
}

.friend-request {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    margin: 7px;
}

.friend-request-button {
    background-color: rgb(28, 127, 240);
    color: white;
    font-size: 13px;
    border-radius: 7%;
    border: 1px solid rgb(47, 120, 244);
    padding: 12px;
    margin: 5px;
    font-weight: bold;
}

.friend-card-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-basis: auto;
    width: fit-content;
}

.friend-card {
    width: 70px;
    height: auto;
    color: black;
    background-color: white;
    margin: 1px;
    padding: 3px;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 4px;

}

.friends-main-flex-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: white;
    border-radius: 7px;
    padding: 5px;
}

.fa-user-times {
    margin-right: 10px
}


.user-name-intro {
    font-weight: bold;
}

.user-email {
    font-size: 1rem;
}

.user-details {
    margin: 10px
}

.center-user-posts-browser-container {
    width: 60%;

}
