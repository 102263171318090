.profile-button-container {
    display: inline-block;
    position: relative;
    justify-content: center;
    /* padding: 5px; */
    /* border-style: solid; */
    /* border-color: rgb(159, 157, 157);
    border-width: thin;
    border-radius: 50%; */
    /* background-color: #06c; */
    cursor: pointer;
    color: inherit;
}

.actual-button {
    border: none;
    background-color: white;
    border-radius: 0px;
}
.actual-button:hover:{
     background-color: rgb(224, 225, 226);
     /* color: white; */
     border: none;
     cursor: pointer;
     padding: 2px;
     width: 100%;
     border-radius: 0px;
}

.fa-solid {
    /* color:rgb(189, 46, 196); */
    padding: 7px;
}

.dropdown-content {
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: #f9f9f9;
    width: 155px;
    box-shadow: 0.5px 0.5px 3px grey;
    padding: 10px;
    z-index: 5;
    top: 75%;
    border-radius: 5px;
    right: 55px;
    font-size: 16px;
    margin: 10px;
}


.username-container:hover {
    /* background-color: rgb(220, 220, 221); */
    color: white;
    border: none;
    cursor: pointer;
    /* padding: 5px; */
    width: 125px;
    border-radius: 0px;
}

.log-out {
    /* padding: 5px; */
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;


}

.log-out:hover {
    background-color: rgb(224, 225, 226);
    /* color: white; */
    border: none;
    cursor: pointer;
    padding: 2px;
    width: 100%;
    border-radius: 0px;
}

.my-spots {
    padding: 5px;
}

.my-reviews {
    padding: 5px;
}

.my-reviews:hover {
    background-color: rgb(120, 194, 255);
    color: white;
    border: none;
    cursor: pointer;
    /* padding: 5px; */
    width: 125px;
    border-radius: 0px;
}


.my-spots:hover {
    background-color: rgb(11, 58, 13);
    color: white;
    border: none;
    cursor: pointer;
    /* padding: 5px; */
    width: 125px;
    border-radius: 0px;
}

#nav-profile-pic:hover {
    background-color: grey;
}

.sign-up-text {
    padding: 5px;
}

.sign-up-text:hover {
    background-color: rgb(109, 124, 109);
    color: white;
    border: none;
    cursor: pointer;
    /* padding: 5px; */
    width: 125px;
    border-radius: 0px;
}

.log-in-text {
    padding: 5px;
}

.log-in-text:hover {
    background-color: rgb(11, 58, 13);
    color: white;
    border: none;
    cursor: pointer;
    /* padding: 5px; */
    width: 125px;
    border-radius: 0px;
}

.profile-page {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: none;
    cursor: pointer;
    /* padding: 5px; */
    width: 100%;
    border-radius: 0px;
    /* margin: 5px; */
}

.profile-page:hover {
     background-color: rgb(224, 225, 226);
     /* color: white; */
     border: none;
     cursor: pointer;
     padding: 2px;
     width: 100%;
     border-radius: 0px;
}

#nav-section-profile-pic {
    width: 33px;
    height: 33px;
    border-radius: 50%;
    object-fit: fill;
    margin: 10px;

}
#nav-profile-pic{
      width: 35px;
      height: 35px;
      border-radius: 50%;
      object-fit: fill;
      margin: 10px;
}
.nav-profile-pic-section {
    background-color: grey;
    border-radius: 50%;
}

.fa-right-from-bracket {

    margin: 10px;
    background-color: grey;
    border-radius: 50%;
}

.dropdown-container-sections {
    display: flex;
}
