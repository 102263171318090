/* frontend/src/context/Modal.css */
#modal {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;



}

#modal-background {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
}

#modal-content {
    position: absolute;
    background-color: white;
    border-radius: 4px;
    width: 350px;
    /* box-shadow:0 0 2px 1px rgb(214, 211, 211); */
}

.title-words{
    font-size: 1em;
    background-color: white;
}

.edit-post-button{
    /* background-color: rgb(28, 88, 192);
    color:white;
    border:none;
    border-radius: 7px;
    padding: 5px;
    width: 100%;
    margin: 5% 0% */
    border: none;
    color: white;
    background-color: rgb(32, 121, 217);
    width: 100%;
    border-radius: 5px;
    padding: 2%;
    margin: 5% 0%;
    font-weight: bold;
    font-size: 15px;
    padding: 10px;
}
.edit-post-button:hover{
    cursor:pointer;
}
.form-text-input-field{
    height:auto;
}


.Edit-Post-Outer-Form-Container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    background-color: white;
    margin: 2%;
    padding: 20px;

}

.fa-pencil {
    border: none;
    background-color: white;
    color: rgb(22, 72, 199)
}

.fa-trash-can {
    border: none;
    background-color: white;
    color: rgb(22, 72, 199)
}


.modal-button{
    background-color: white;
    border:none
}

.modal-button:hover{
    cursor: pointer;
}
