.comment-card-main-container{

    /* margin: 10px; */
    /* border: 1px solid rgb(197, 197, 197); */
    /* border-radius: 20px; */
    /* padding: 2%; */
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 2%
}
.comment-info-top-container {
    /* border: 2px solid red; */
    padding: 10px;

    /* background-color: white; */

}

.comment-title {
    /* background-color: white; */

    font-size: 15px;
    font-weight: bold;
    display: flex;
    align-self: flex-start;
    margin: 3px 0px;


}

.comment-card-content-container {
    font-size: 15px;
    background-color: rgb(234, 233, 233);
    border:none;
    border: none;
    border-radius: 20px;
    padding: 15px;
    /* max-width:200px;
    min-width: 200px; */
    word-wrap:break-word;
    text-align: flex-start;
    line-height: 20px;


}
.comment-card-content-container:hover {
    white-space: normal;
}

.comment-card-container {
    /* margin: 10px; */

}

.comments-browser-cards-container{

   border-radius: 20px;
}

.Edit-Delete-Post-Button{
    border:none;
    background-color: white;
}

.Edit-Delete-Post-Button:hover{
    cursor: pointer;
}

.comments-browser-container{
    /* border: 1px solid grey; */
    /* margin: 2px; */
    border-radius: 7px;

}

.post-number-of-comments{
    display: flex;
    color:grey;
    font-weight:bold;
    justify-content: flex-end;
    padding: 10px 20px;
}

.comment-card-user-pic {
border-radius: 50%;
width: 40px;
margin-right: 15px;
height:40px;
object-fit: cover;
}

.comment-card-header-info{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
}

.comment-card-user-pic-name{
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 20px;
}
